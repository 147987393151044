import { ChangeEvent, FormEvent, useCallback, useState } from "react";
import { useIntl } from "@/i18n/i18n-client";
import { DebounceInput } from "react-debounce-input";
import SearchResults from "./SearchResults";
import { useStore } from "@/lib/storeData/StoreContext";
import { ProductType, useSearchProductsLazyQuery } from "@/generated/graphql";
import { useClickOutside } from "@/hooks/useClickOutside";
import { Search } from "@/assets/Icons/Search";
import InputWithIcon from "@/shared/globals/UiElements/InputWithIcon";
import { useRouter } from "@/i18n/i18n-navigation";
import { Overlay } from "@/shared/globals";
import { useEscapeAndStopScrollingEffect } from "@/hooks/useEscapeAndStopScrollingEffect";
import { ZIndex } from "@/shared/globals/types";
import MotionElementWrapper from "@/shared/globals/MotionElementWrapper";

const SearchBar = () => {
  const intl = useIntl();
  const { id: storeId } = useStore();
  const [isOpenedResults, setIsOpenedResults] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const router = useRouter();

  const [queryProducts, { data, loading, refetch }] =
    useSearchProductsLazyQuery({
      variables: { filter: { storeIds: [storeId] }, connection: { first: 7 } },
      ssr: false,
    });

  const handleClickOutside = useCallback(() => {
    setIsOpenedResults(false);
  }, []);
  const { ref } = useClickOutside(handleClickOutside);

  const handleInputFocus = () => {
    if (!data) queryProducts();
    setIsOpenedResults(true);
  };

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (searchValue.length <= 2) return;
    router.push(`/search?query=${searchValue}`);
    handleClickOutside();
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    refetch({
      filter: { storeIds: [storeId], title: e?.target?.value?.trim() },
    });
    setIsOpenedResults(true);
    setSearchValue(e?.target?.value?.trim());
  };

  const perResultData = data?.products?.nodes?.map((product) => {
    const simpleProductPrice =
      product.type === ProductType.Simple &&
      product?.variants?.nodes?.[0]?.price?.amount;
    const customProductPrice =
      product.type === ProductType.Custom && product?.initialPrice?.amount;

    return {
      title: product.title,
      img: product.images?.[0],
      price: simpleProductPrice || customProductPrice || 0,
      handle: product.handle,
      collectionHandle: product.collections?.nodes?.[0]?.handle || "",
    };
  });

  useEscapeAndStopScrollingEffect({
    isOpen: isOpenedResults,
    onClose: handleClickOutside,
  });

  return (
    <div className=" w-full" ref={ref}>
      <form onSubmit={handleFormSubmit}>
        <DebounceInput
          // @ts-ignore
          element={InputWithIcon}
          data-test="type-search"
          debounceTimeout={500}
          prefix={
            <div className="text-gray-500">
              <Search width="20" />
            </div>
          }
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          placeholder={intl.formatMessage({
            defaultMessage: "Search store",
            id: "wb2+/M",
          })}
          borderRadius="2.25rem"
          className="shadow-xs overflow-hidden relative z-DROPDOWN flex-1 py-0.5"
        />
      </form>
      {isOpenedResults && (
        <>
          <MotionElementWrapper
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: -35, opacity: 1 }}
            className="relative z-TOP"
          >
            <SearchResults
              data={perResultData}
              loading={loading}
              closeModal={handleClickOutside}
            />
          </MotionElementWrapper>
          <div
            className="absolute top-0 left-0 w-screen h-screen"
            onClick={handleClickOutside}
          >
            <Overlay
              style={{ zIndex: ZIndex["BACKGROUND"] }}
              onClick={handleClickOutside}
              isAbsolute
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SearchBar;
