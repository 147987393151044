import { ChangeEvent, FormEvent, useCallback, useState } from "react";
import styled from "styled-components";
import { DebounceInput } from "react-debounce-input";
import { useIntl, useLocale } from "@/i18n/i18n-client";
import { Search as SearchIcon } from "@/assets/Icons/Search";
import { Delete } from "@/assets/Icons/Delete";
import { Overlay } from "@/shared/globals";
import Flex from "@/shared/globals/UiElements/Flex";
import { ProductType, useSearchProductsLazyQuery } from "@/generated/graphql";
import { useStore } from "@/lib/storeData/StoreContext";
import InputWithIcon from "@/shared/globals/UiElements/InputWithIcon";
import { rtl, themeColor } from "@/shared/styles-utils";
import { useEscapeAndStopScrollingEffect } from "@/hooks/useEscapeAndStopScrollingEffect";
import { useClickOutside } from "@/hooks/useClickOutside";
import SearchResults from "./SearchResults";
import { useUpdateEffect } from "@/hooks/useUpdateEffect";
import { useRouter } from "@/i18n/i18n-navigation";

const SearchOverlay = () => {
  const { storeId } = useStore();
  const { replace } = useRouter();
  const intl = useIntl();
  const { code: activeLocale } = useLocale();

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [queryProducts, { data, loading, refetch }] =
    useSearchProductsLazyQuery();

  useUpdateEffect(() => {
    if (isOpen)
      queryProducts({
        variables: {
          filter: { storeIds: [storeId] },
          connection: { first: 7 },
        },
        ssr: false,
      });
  }, [isOpen, queryProducts]);

  const handleClickOutside = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEscapeAndStopScrollingEffect({
    isOpen,
    onClose: handleClickOutside,
  });
  const { ref } = useClickOutside(handleClickOutside);

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (searchValue.length <= 2) return;
    replace(`/${activeLocale}/search?query=${searchValue}`);
    handleClickOutside();
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    refetch({
      filter: { storeIds: [storeId], title: e?.target?.value?.trim() },
    });
    setSearchValue(e?.target?.value?.trim());
  };

  const perResultData = data?.products?.nodes?.map((product) => {
    const simpleProductPrice =
      product.type === ProductType.Simple &&
      product?.variants?.nodes?.[0]?.price?.amount;
    const customProductPrice =
      product.type === ProductType.Custom && product?.initialPrice?.amount;

    return {
      title: product.title,
      img: product.images?.[0],
      price: simpleProductPrice || customProductPrice || 0,
      handle: product.handle,
      collectionHandle: product.collections?.nodes?.[0]?.handle || "",
    };
  });

  return (
    <>
      <span onClick={() => setIsOpen(true)}>
        <SearchIcon />
      </span>

      <Container ref={ref} isOpen={isOpen}>
        <Search spacing="l">
          <form style={{ width: "100%" }} onSubmit={handleFormSubmit}>
            <DebounceInput
              // @ts-ignore
              element={InputWithIcon}
              data-test="type-search"
              debounceTimeout={500}
              prefix={<SearchIcon width="13" />}
              onChange={handleInputChange}
              placeholder={intl.formatMessage({
                defaultMessage: "Search store",
                id: "wb2+/M",
              })}
              autoFocus
            />
          </form>

          <CloseBox onClick={handleClickOutside}>
            <Delete small />
          </CloseBox>
        </Search>

        <SearchResults
          data={perResultData}
          loading={loading}
          closeModal={handleClickOutside}
          noContainer
          imageSize={60}
        />
      </Container>
    </>
  );
};

export default SearchOverlay;

const Container = styled(Overlay)<{ isOpen: boolean }>`
  background-color: ${themeColor("white")};
  opacity: 1;
  bottom: 60px; // = FixedNav height
  z-index: unset;

  transition: all 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  transform: ${({ isOpen }) =>
    isOpen ? "translateX(0)" : rtl("translateX(-120%)", "translateX(120%)")};
`;

const Search = styled(Flex)`
  padding: 30px 20px 20px;
  box-shadow: inset 0px -1px 0px 0px ${({ theme }) => theme.special.border};
`;

const CloseBox = styled.div`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.bg.inactive};
  border-radius: 4px;
`;
