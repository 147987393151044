import { ChangeEvent, FormEvent, useCallback, useState } from "react";
import styled from "styled-components";
import { useIntl } from "@/i18n/i18n-client";
import { DebounceInput } from "react-debounce-input";
import SearchResults from "./SearchResults";
import { useStore } from "@/lib/storeData/StoreContext";
import { ProductType, useSearchProductsLazyQuery } from "@/generated/graphql";
import { useClickOutside } from "@/hooks/useClickOutside";
import { Search } from "@/assets/Icons/Search";
import InputWithIcon from "@/shared/globals/UiElements/InputWithIcon";
import { useRouter } from "@/i18n/i18n-navigation";
import { Overlay } from "@/shared/globals";
import { useEscapeAndStopScrollingEffect } from "@/hooks/useEscapeAndStopScrollingEffect";
import { ZIndex } from "@/shared/globals/types";

interface SearchBarProps {
  mobile?: boolean;
}

const SearchBar = ({ mobile }: SearchBarProps) => {
  const intl = useIntl();
  const { id: storeId } = useStore();
  const [isOpenedResults, setIsOpenedResults] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const router = useRouter();

  const [queryProducts, { data, loading, refetch }] =
    useSearchProductsLazyQuery({
      variables: { filter: { storeIds: [storeId] }, connection: { first: 5 } },
      ssr: false,
    });

  const handleClickOutside = useCallback(() => {
    setIsOpenedResults(false);
  }, []);
  const { ref } = useClickOutside(handleClickOutside);

  const handleInputFocus = () => {
    if (!data) queryProducts();
    setIsOpenedResults(true);
  };

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (searchValue.length <= 2) return;
    router.push(`/search?query=${searchValue}`);
    handleClickOutside();
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    refetch({
      filter: { storeIds: [storeId], title: e?.target?.value?.trim() },
    });
    setIsOpenedResults(true);
    setSearchValue(e?.target?.value?.trim());
  };

  const perResultData = data?.products?.nodes?.map((product) => {
    const simpleProductPrice =
      product.type === ProductType.Simple &&
      product?.variants?.nodes?.[0]?.price?.amount;
    const customProductPrice =
      product.type === ProductType.Custom && product?.initialPrice?.amount;

    return {
      title: product.title,
      img: product.images?.[0],
      price: simpleProductPrice || customProductPrice || 0,
      handle: product.handle,
      collectionHandle: product.collections?.nodes?.[0]?.handle || "",
    };
  });

  useEscapeAndStopScrollingEffect({
    isOpen: isOpenedResults,
    onClose: handleClickOutside,
  });

  return (
    <StyledDiv ref={ref} mobile={!!mobile}>
      <form onSubmit={handleFormSubmit}>
        <DebounceInput
          // @ts-ignore
          element={InputWithIcon}
          data-test="type-search"
          debounceTimeout={500}
          width="400px"
          suffix={<Search width="13" />}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          placeholder={intl.formatMessage({
            defaultMessage: "Search store",
            id: "wb2+/M",
          })}
        />
      </form>
      {isOpenedResults && (
        <>
          <SearchResults
            data={perResultData}
            loading={loading}
            closeModal={handleClickOutside}
          />
          <Overlay
            style={{ zIndex: ZIndex["BACKGROUND"] }}
            onClick={handleClickOutside}
          />
        </>
      )}
    </StyledDiv>
  );
};

export default SearchBar;

const StyledDiv = styled.div<{ mobile: boolean }>`
  position: relative;
  display: ${({ mobile }) => (mobile ? "block" : "none")};
  margin-bottom: 12px;

  @media (min-width: 768px) {
    display: ${({ mobile }) => (mobile ? "none" : "block")};
    margin-bottom: 0;
  }
`;
